import html2pdf from "html2pdf.js";

const useGeneratePDF = () => {

  const generatePDF = (parentElementId, filename, elementsToHide) => {
    let parentElement = document.getElementById(`${parentElementId}`);

    let parentElementClone = parentElement.cloneNode(true);

    for (const elementClass of elementsToHide) {
      if (parentElementClone.querySelector(`${elementClass}`)) {

        parentElementClone.querySelector(`${elementClass}`).style.display = 'none';
      }
    }

    let options = {
      filename,
      image: {
        type: "jpeg",
        quality: 1
      },
      margin: [4, 4, 4, 4],
      jsPDF: {
        format: "a3",
        orientation: "landscape", //landscape
      },
    };

    html2pdf().from(parentElementClone).set(options).save();

    parentElementClone = null;
  };


  return {
    generatePDF
  };

};

export default useGeneratePDF;