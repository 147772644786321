<template>
  <div class="pd-20">
    <a-page-header
      class="pd-0"
      sub-title="Connect Miles"
      @back="() => $router.go(-1)"
    >
      <div slot="title">
        <h1>Relatório de Milhas</h1>
      </div>
      <div slot="extra">
        <downloadExcel
          v-if="$root.isAdmin()"
          class="btn btn-default"
          :header="excelFile.header"
          :name="excelFile.fileName"
          :data="excelFile.data"
          :fields="excelFile.collumns"
          :footer="excelFile.footer"
          style="display: inline-block"
        >
          <img
            class="c-pointer ml-10"
            src="@/assets/images/dashboard/excel.png"
          />
        </downloadExcel>
      </div>
    </a-page-header>

    <a-row class="haya-panels" :gutter="20">
      <a-col :span="6">
        <div class="box">
          <div class="title"></div>
          <div class="total cblue">{{ totalMiles }}</div>
          <div class="txt">Total de Milhas</div>
          <div class="footer">R$ {{ totalMoney }}</div>
        </div>
      </a-col>
      <a-col :span="6">
        <div class="box">
          <div class="title"></div>
          <div class="total cgreen">{{ totalAvailableMiles }}</div>
          <div class="txt">Disponível</div>
          <div class="footer">R$ {{ totalAvailableMoney }}</div>
        </div>
      </a-col>
      <a-col :span="6">
        <div class="box">
          <div class="title"></div>
          <div class="total red">-{{ totalSpentMiles }}</div>
          <div class="txt">Utilizado</div>
          <div class="footer">R$ {{ totalMoneySpent }}</div>
        </div>
      </a-col>
      <a-col :span="6">
        <div class="box">
          <div class="title"></div>
          <div class="total">{{ totalMilesCards }}</div>
          <div class="txt">Número de cartões</div>
          <div class="footer" style="color: transparent">.</div>
        </div>
      </a-col>
    </a-row>

    <a-collapse
      class="travel-filters expandable mt-20 mb-20"
      activeKey="0"
      expandIconPosition="right"
    >
      <a-collapse-panel key="1">
        <template slot="header">
          <a-icon type="filter" class="mr-5" /> FILTRAR
        </template>

        <a-row class="mt-5" :gutter="20">
          <a-col :span="2">
            <div class="travel-input">
              <label class="filled">ID do cartão</label>
              <a-input
                placeholder=""
                v-model="milesCards.filters.id"
                @change="getMilesCards()"
              />
            </div>
          </a-col>

          <a-col :span="4">
            <div class="travel-input">
              <label class="filled">Fornecedor</label>
              <a-auto-complete
                :data-source="
                  suppliers.map(({ first_name, last_name, id }) => ({
                    value: id,
                    text: `${id} - ${first_name} ${last_name}`,
                  }))
                "
                v-model="milesCards.filters.supplier"
                style="width: 100%"
                placeholder="Buscar fornecedores..."
                @search="searchSupplier"
                @change="getMilesCards()"
              />
            </div>
          </a-col>

          <a-col :span="4">
            <div class="travel-input">
              <label class="filled">Cias</label>
              <a-select
                class="travel-input"
                placeholder="Selecione a cia aérea"
                mode="multiple"
                v-model="milesCards.filters.airlines.selected"
                optionFilterProp="txt"
                show-search
                style="width: 100%"
                @change="getMilesCards()"
              >
                <a-select-option
                  v-for="(item, index) of milesCards.filters.airlines.list"
                  :key="index"
                  :value="item.value"
                  :txt="item.label"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>

          <a-col :span="4">
            <div class="travel-input">
              <label class="filled">Programas de Milhas</label>
              <a-select
                class="travel-input"
                placeholder="Selecione o programa"
                mode="multiple"
                v-model="milesCards.filters.milesPrograms.selected"
                show-search
                style="width: 100%"
                @change="getMilesCards()"
              >
                <a-select-option
                  v-for="(item, index) of milesCards.filters.milesPrograms.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>

          <a-col :span="5">
            <div class="travel-input">
              <label class="filled">Período</label>
              <a-range-picker
                v-model="milesCards.filters.period.selected"
                :format="['DD/MM/YYYY']"
                :value-format="'YYYY-MM-DD'"
                @change="getMilesCards()"
              />
            </div>
          </a-col>
        </a-row>
      </a-collapse-panel>
    </a-collapse>

    <div class="travel-table last-itens">
      <HayaTable
        :data-source="milesCards.list"
        :loading="milesCards.loading"
        :pagination="false"
        :paginator="milesCards.pagination"
        @getList="getMilesCards(milesCards.pagination.page)"
        :columns="[
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            class: 'id',
            width: 80,
          },
          {
            title: 'Fornecedor',
            dataIndex: 'supplier',
            key: 'supplier',
            scopedSlots: { customRender: 'supplier' },
          },
          {
            title: 'Pgm. Milhas',
            dataIndex: 'miles_program',
            key: 'miles_program',
          },
          {
            title: 'Total Milhas',
            dataIndex: 'total_miles',
            key: 'total_miles',
            class: 'total-miles',
          },
          {
            title: 'Disponível',
            dataIndex: 'available_miles',
            key: 'available_miles',
            class: 'available-miles',
          },
          {
            title: 'Utilizado',
            dataIndex: 'spent_miles',
            key: 'spent_miles',
            class: 'spent-miles',
          },
          {
            title: 'Valor/1000',
            scopedSlots: { customRender: 'price_per_miles' },
          },
          {
            title: 'Preço Especial',
            scopedSlots: { customRender: 'special_price' },
          },
          {
            title: 'Preço Venda ',
            scopedSlots: { customRender: 'selling_price' },
          },
          {
            title: 'Validade',
            scopedSlots: { customRender: 'expires_at' },
          },
          {
            title: 'Data de cadastro',
            scopedSlots: { customRender: 'created' },
          },
          {
            title: '',
            align: 'right',
            fixed: 'right',
            scopedSlots: { customRender: 'action' },
            width: 190,
          },
        ]"
      >
        <template slot="id" slot-scope="record">
          {{ record.id }}
        </template>
        <template slot="supplier" slot-scope="record">
          {{ `${record.first_name} ${record.last_name}`.toUpperCase() }}
        </template>
        <template slot="quantity" slot-scope="record">
          {{ record.id }}
        </template>
        <template slot="spent_miles" slot-scope="record">
          {{ record.spent_miles }}
        </template>
        <template slot="price_per_miles" slot-scope="record">
          R$ {{ record.price_per_miles }}
        </template>
        <template slot="special_price" slot-scope="record">
          R$ {{ record.special_price }}
        </template>
        <template slot="selling_price" slot-scope="record">
          R$ {{ record.selling_price }}
        </template>
        <template slot="expires_at" slot-scope="record">
          {{ formatDate(record.expires_at) }}
        </template>
        <template slot="updated" slot-scope="record">
          {{ formatDateTime(record.updated) }}
        </template>
        <template slot="created" slot-scope="record">
          {{ formatDateTime(record.created) }}
        </template>
        <span class="table-actions" slot="action" slot-scope="milesCard">
          <a-tooltip placement="top" title="Detalhamento">
            <a class="view" @click="view(milesCard.id)">
              <a-icon slot="prefix" type="eye-svg" />
            </a>
          </a-tooltip>
        </span>
      </HayaTable>
    </div>
  </div>
</template>

<script>
import HayaTable from "@/components/general/HayaTable.vue";
import milesCardsMixin from "@/mixins/miles-cards/milesCardsMixin";
import suppliersMixins from "@/mixins/suppliersMixins";
import formatThings from "@/mixins/general/formatThings";

export default {
  mixins: [milesCardsMixin, suppliersMixins, formatThings],
  components: { HayaTable },
  data() {
    return {
      excelFile: {
        header: "RELATÓRIO DE MILHAS",
        fileName: "RELATÓRIO DE MILHAS.xls",
        collumns: {
          ID: "id",
          Fornecedor: {
            field: "supplier",
            callback: (supplier) => {
              return `${supplier.first_name} ${supplier.last_name}`;
            },
          },
          "Pgm. Milhas": "miles_program",
          "Total Milhas": "total_miles",
          Disponível: "available_miles",
          Utilizado: "spent_miles",
          "Valor/1000": "price_per_miles",
          "Preço Especial (R$)": "special_price",
          "Preço Venda": "selling_price",
          Validade: "expires_at",
          // Usuário: {
          //     field: "user",
          //     callback: (user) => {
          //         return `${user.first_name} ${user.last_name}`;
          //     },
          // },
          "Data de cadastro": "created",
        },
        data: [],
        footer: [],
      },
    };
  },
  beforeMount() {
    document.title = "Relatório de Milhas - HAYA";
    this.activeTab = "available";
    this.getMilesCards();
    this.getAirlines();
  },
  methods: {
    view(id) {
      window.open(`/miles-cards/view/${id}`, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped></style>
