import { ref, reactive, computed } from "vue";
import Vue from 'vue';

const useMilesCardsTransactions = () => {

  const loading = ref(false);

  const pagination = reactive({
    page: 1,
    perPage: 10,
    total: 0,
    totalPages: 0,
  });

  const details = ref({});

  const list = ref([]);

  const filters = reactive({
    users: {
      list: [],
      selected: [],
    },
    operation: {
      list: [{
        label: "Cancelado",
        value: "canceled",
      }, {
        label: "Emissão",
        value: "subtract",
      }, {
        label: "Adição",
        value: "add",
      }, {
        label: "Novo",
        value: "new",
      },],
      selected: ['refund'],
    },
    period: {
      selected: [],
    },
    milesCardId: "",
    operationId: "",
    contractId: "",
    searchTerm: "",
    order: "desc",
    orderBy: "created",
  });

  const getMilesCardTransactions = async (samePage) => {
    let params = "";

    loading.value = true;

    if (filters.searchTerm) params += `&s=${filters.searchTerm}`;

    if (filters.contractId) params += `&contract_id=${filters.contractId}`;

    if (filters.milesCardId) params += `&milescard_id=${filters.milesCardId}`;

    if (filters.operationId) params += `&milescard_transaction_id=${filters.operationId}`;

    if (filters.operation.selected.length > 0) params += `&operation=${filters.operation.selected}`;

    if (filters.users.selected.length > 0) params += `&user_id=${filters.users.selected}`;

    if (filters.period.selected.length) params += params += `&period=${filters.period.selected[0]}|${filters.period.selected[1]}`;

    params += `&order=${filters.order}&order-by=${filters.orderBy}`;

    try {
      const { data } = await Vue.prototype.$http.get(`/miles-card-transactions/list?page=${pagination.page}&per_page=${pagination.perPage}${params}`);
      if (!samePage) pagination.page = 1;
      pagination.total = data.meta.total;
      pagination.totalPages = data.meta.total_pages;
      list.value = data.data;
    } catch ({ response }) {
      list.value = response.data.data;
      pagination.total = response.data.meta.total;
      pagination.totalPages = response.data.meta.total_pages;
    } finally {
      loading.value = false;
    }
  };


  const formatMilesOperation = (transaction) => {
    let miles = "";
    if (transaction.operation == "new") {
      miles = `+${transaction.total_miles}`;
    }

    if (transaction.operation == "add") {
      miles = `+${transaction.total_miles}`;
    }

    if (transaction.operation == "return-miles") {
      miles = `+${transaction.total_miles}`;
    }

    if (transaction.operation == "canceled") {
      miles = `+${transaction.total_miles}`;
    }

    if (transaction.operation == "subtract") {
      miles = `-${transaction.total_miles}`;
    }
    return miles;
  };

  const formatOperation = (transaction) => {
    let operation = "";
    let vcm = "";

    if (transaction.operation == "new") {
      if (transaction.is_vcm == "1") {
        vcm = `<font class="corange" style="font-size: 24px; font-size: 16px;"> <a-tooltip> <template slot="title"> Milhas do VCM </template> • </a-tooltip> </font>`;
      }

      operation = `<font class="tag new">NOVO ${vcm}</font>`;
    }

    if (transaction.operation == "add") {
      operation = `<font class="tag add">ADIÇÃO</font>`;
    }

    if (transaction.operation == "subtract") {
      operation = `<font class="tag return">EMISSÃO</font>`;
    }

    if (transaction.operation == "canceled") {
      operation = `<font class="tag subtract">CANCELADO</font>`;
    }

    if (transaction.operation == "refund") {
      operation = `<font class="tag return">REEMBOLSO</font>`;
    }

    if (transaction.operation == "adjust") {
      operation = `<font class="tag adjust">AJUSTE</font>`;
    }

    if (transaction.operation == "purchase-canceled") {
      operation = `<font class="tag cancel-purchase">CANCELAMENTO DE COMPRA</font>`;
    }

    return operation;
  };

  const listUsers = ref([]);
  const totalUsers = computed(() => listUsers.value.length);
  const getUsers = async () => {
    try {
      const { data } = await Vue.prototype.$http.post("/user/read");
      listUsers.value = data;
      listUsers.value.push({
        first_name: "Todos usuários",
        last_name: "",
        id: -1,
      });
      listUsers.value.reverse();
    } catch (error) {
      listUsers.value = [];
    }
  };

  return {
    loading,
    pagination,
    details,
    list,
    filters,
    getMilesCardTransactions,
    formatMilesOperation,
    formatOperation,
    getUsers,
    totalUsers,
    listUsers,
  };
};


export default useMilesCardsTransactions;