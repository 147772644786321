<template>
  <div>
    <div v-if="$route.params.id == 'miles'">
      <MilesCardsMilesManagement />
    </div>

    <div v-if="$route.params.id == 'emissions'">
      <MilesCardsEmissions />
    </div>

    <div v-if="$route.params.id == 'blocks'">
      <MilesCardsBlocks />
    </div>

    <div v-if="$route.params.id == 'purchases'">
      <MilesCardsPurchases />
    </div>

    <div v-if="$route.params.id == 'checkin'">
      <MilesCardsCheckin />
    </div>

    <div v-if="$route.params.id == 'card-taxes'">
      <MilesCardsTaxes />
    </div>

    <div v-if="$route.params.id == 'refund'">
      <MilesCardsRefund />
    </div>
  </div>
</template>

<script>
import MilesCardsBlocks from "@/views/miles/reports/MilesCardsBlocks.vue";
import MilesCardsCheckin from "@/views/miles/reports/MilesCardsCheckin.vue";
import MilesCardsEmissions from "@/views/miles/reports/MilesCardsEmissions.vue";
import MilesCardsMilesManagement from "@/views/miles/reports/MilesCardsMilesManagement.vue";
import MilesCardsPurchases from "@/views/miles/reports/MilesCardsPurchases.vue";
import MilesCardsRefund from "@/views/miles/reports/MilesCardsRefund.vue";
import MilesCardsTaxes from "@/views/miles/reports/MilesCardsTaxes.vue";

export default {
  components: {
    MilesCardsBlocks,
    MilesCardsMilesManagement,
    MilesCardsEmissions,
    MilesCardsPurchases,
    MilesCardsCheckin,
    MilesCardsTaxes,
    MilesCardsRefund,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
