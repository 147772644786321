<template>
    <div id="miles-cards-emissions" class="pd-20">
        <a-page-header
            class="pd-0"
            sub-title="Connect Miles"
            @back="() => $router.go(-1)"
        >
            <h1 slot="title">Relatório de Reembolsos</h1>
            <div slot="extra">
                <downloadExcel
                    v-if="$root.isAdmin()"
                    class="btn btn-default"
                    :header="excelFile.header"
                    :name="excelFile.fileName"
                    :data="excelFile.data"
                    :fields="excelFile.collumns"
                    :footer="excelFile.footer"
                    style="display: inline-block"
                >
                    <img
                        class="c-pointer ml-10"
                        src="@/assets/images/dashboard/excel.png"
                    />
                </downloadExcel>
            </div>
        </a-page-header>

        <a-row class="haya-panels" :gutter="20">
            <a-col :span="6">
                <div class="box">
                    <div class="title"></div>
                    <div class="total cblue">
                        {{ milesCardTransactions.meta.total_miles }}
                    </div>
                    <div class="txt">Total de Milhas</div>
                    <div class="footer">
                        Valor: R$ {{ milesCardTransactions.meta.total_value }}
                    </div>
                </div>
            </a-col>

            <a-col :span="3">
                <div class="box">
                    <div class="title"></div>
                    <div class="total cprimary">
                        {{ milesCardTransactions.meta.total_refunds }}
                    </div>
                    <div class="txt">Reembolsos</div>
                    <div class="footer"></div>
                </div>
            </a-col>
        </a-row>

        <a-collapse
            class="travel-filters expandable mt-20 mb-10"
            activeKey="0"
            expandIconPosition="right"
        >
            <a-collapse-panel key="1">
                <template slot="header">
                    <a-icon type="filter" class="mr-5" /> FILTRAR
                </template>

                <a-row
                    class="mt-5"
                    :gutter="20"
                    style="margin-left: 0; margin-right: 0"
                >
                    <a-col :span="4">
                        <div class="travel-input">
                            <label class="filled">Fornecedor</label>
                            <a-auto-complete
                                :data-source="
                                    suppliers.map(
                                        ({ first_name, last_name, id }) => ({
                                            value: id,
                                            text: `${id} - ${first_name} ${last_name}`,
                                        })
                                    )
                                "
                                v-model="milesCardTransactions.filters.supplier"
                                style="width: 100%"
                                placeholder="Buscar fornecedores..."
                                @search="searchSupplier"
                                @select="getMilesCardTransactions()"
                                @change="
                                    milesCardTransactions.filters.supplier
                                        ? ''
                                        : getMilesCardTransactions()
                                "
                            />
                        </div>
                    </a-col>

                    <a-col :span="4">
                        <div class="travel-input">
                            <label class="filled">Cias</label>
                            <a-select
                                class="travel-input"
                                placeholder="Selecione a cia aérea"
                                mode="multiple"
                                v-model="
                                    milesCardTransactions.filters.airlines
                                        .selected
                                "
                                optionFilterProp="txt"
                                show-search
                                style="width: 100%"
                                @change="getMilesCardTransactions()"
                            >
                                <a-select-option
                                    v-for="(item, index) of milesCards.filters
                                        .airlines.list"
                                    :key="index"
                                    :value="item.value"
                                    :txt="item.label"
                                >
                                    {{ item.label }}
                                </a-select-option>
                            </a-select>
                        </div>
                    </a-col>

                    <a-col :span="6">
                        <div class="travel-input">
                            <label class="filled">Faixa de Milhas</label>
                            <a-input-group compact>
                                <a-input
                                    type="number"
                                    style="
                                        width: 45%;
                                        text-align: center;
                                        border-radius: 0;
                                    "
                                    placeholder="Min."
                                    v-model="
                                        milesCardTransactions.filters.price.min
                                    "
                                    @change="getMilesCardTransactions()"
                                />
                                <a-input
                                    style="
                                        width: 10%;
                                        border-left: 0;
                                        pointer-events: none;
                                        backgroundcolor: #fff;
                                        border: 0;
                                    "
                                    placeholder="até"
                                    disabled
                                />
                                <a-input
                                    type="number"
                                    style="
                                        width: 45%;
                                        text-align: center;
                                        border-left: 0;
                                        border-radius: 0;
                                    "
                                    placeholder="Max"
                                    v-model="
                                        milesCardTransactions.filters.price.max
                                    "
                                    @change="getMilesCardTransactions()"
                                />
                            </a-input-group>
                        </div>
                    </a-col>

                    <a-col v-if="$root.isAdmin()" :span="4">
                        <div class="travel-input">
                            <label class="filled">Usuários</label>
                            <a-select
                                class="travel-input"
                                placeholder="Selecione um ou mais usuários"
                                optionFilterProp="txt"
                                mode="multiple"
                                v-model="
                                    milesCardTransactions.filters.users.selected
                                "
                                show-search
                                style="width: 100%"
                                @change="getMilesCardTransactions()"
                            >
                                <a-select-option
                                    v-for="(item, index) of listUsers"
                                    :key="index"
                                    :value="item.id"
                                    :txt="item.first_name"
                                >
                                    <div v-if="item.id == -1">
                                        {{ item.first_name }}
                                        {{ item.last_name }}
                                    </div>
                                    <div v-else>
                                        {{ item.id }} -
                                        {{ item.first_name }}
                                        {{ item.last_name }}
                                    </div>
                                </a-select-option>
                            </a-select>
                        </div>
                    </a-col>
                    <a-col :span="6">
                        <div class="travel-input">
                            <label class="filled">Período</label>
                            <a-range-picker
                                v-model="
                                    milesCardTransactions.filters.period
                                        .selected
                                "
                                :format="['DD/MM/YYYY']"
                                :value-format="'YYYY-MM-DD'"
                                @change="getMilesCardTransactions"
                            />
                        </div>
                    </a-col>
                </a-row>

                <a-row>
                    <a-col :span="6">
                        <a-checkbox
                            v-model="milesCardTransactions.filters.isManual"
                            style="
                                font-size: 13px;
                                font-weight: 500;
                                position: relative;
                            "
                            @change="getMilesCardTransactions()"
                        >
                            Emissão/Cancelamento manual
                        </a-checkbox>
                    </a-col>
                </a-row>
            </a-collapse-panel>
        </a-collapse>

        <div class="travel-table">
          <!-- {{milesCardTransactions.pagination}} -->
            <HayaTable
                :columns="[
                    {
                        title: 'ID ',
                        dataIndex: 'milescard_transaction_id',
                        key: 'milescard_transaction_id',
                        class: 'milescard_transaction_id',
                    },
                    {
                        title: 'ID Cartão Milhas',
                        dataIndex: 'milescard_id',
                        key: 'milescard_id',
                        class: 'milescard_id',
                        sorter: true,
                    },
                    {
                        title: 'Fornecedor',
                        scopedSlots: {
                            customRender: 'supplier',
                        },
                    },
                    {
                        title: 'Pgm. Milhas',
                        scopedSlots: {
                            customRender: 'miles_program',
                        },
                    },
                    {
                        title: 'ID Contrato',

                        scopedSlots: {
                            customRender: 'contract_id',
                        },
                    },
                    {
                        title: 'Data Reembolso',
                        scopedSlots: {
                            customRender: 'created',
                        },
                    },
                    {
                        title: 'Quantidade Reembolso',
                        scopedSlots: {
                            customRender: 'total_miles',
                        },
                        key: 'total_miles',
                        sorter: true,
                    },
                    {
                        title: 'Valor Compra (R$)',
                        dataIndex: 'suggested_value',
                        key: 'suggested_value',
                        sorter: true,
                    },
                    {
                        title: 'Valor Venda (R$)',
                        dataIndex: 'value',
                        key: 'value',
                        sorter: true,
                    },

                    {
                        title: 'Obs',
                        dataIndex: 'extra_info',
                        width: 200,
                        key: 'extra_info',
                        scopedSlots: {
                            customRender: 'extra_info',
                        },
                    },
                    {
                        title: 'Manual',
                        scopedSlots: {
                            customRender: 'is_manual_emission',
                        },
                    },
                    {
                        title: 'Usuário',
                        dataIndex: 'user',
                        key: 'user',
                        scopedSlots: {
                            customRender: 'user',
                        },
                    },
                ]"
                :data-source="milesCardTransactions.list"
                :loading="milesCardTransactions.loading"
                :pagination="false"
                :paginator="milesCardTransactions.pagination"
                rowKey="id"
                @getList="getMilesCardTransactions()"
                @change="milesCardTransactionsTableChange"
            >
                <div
                    slot="total_miles"
                    slot-scope="record"
                    :class="record.is_canceled == '1' ? 'line-through' : ''"
                >
                    {{ record.total_miles }}
                </div>

                <div slot="supplier" slot-scope="record">
                    <div v-if="record.supplier">
                        {{ record.supplier.first_name }}
                        {{ record.supplier.last_name }}
                    </div>

                    <div v-if="record.supplier == false">
                        {{ record }}
                    </div>
                </div>

                <div slot="miles_program" slot-scope="record">
                    {{ record.miles_card.miles_program }}
                </div>

                <div slot="is_manual_emission" slot-scope="record">
                    <div v-if="record.is_manual_emission == 1">
                        <a-icon class="cgreen" type="check" />
                    </div>
                </div>

                <div slot="value" slot-scope="record">{{ record }}</div>

                <div slot="operation" slot-scope="record">
                    <span v-html="formatOperation(record)" />
                </div>

                <div slot="contract_id" slot-scope="record">
                    <div v-if="record.contract_id > 0">
                        <a
                            :href="`/contracts/edit/${record.contract_id}`"
                            target="_blank"
                        >
                            {{ record.contract_id }}
                        </a>
                    </div>
                </div>

                <div slot="milescard_order_id" slot-scope="record">
                    {{
                        record.milescard_order_id != 0
                            ? record.milescard_order_id
                            : ""
                    }}
                </div>

                <div slot="user" slot-scope="record">
                    {{ record.first_name }} {{ record.last_name }}
                </div>

                <template slot="created" slot-scope="record">
                    {{ record.created }}
                </template>
            </HayaTable>
        </div>
    </div>
</template>

<script>
import { onBeforeMount } from "vue";
import useMilesCardsTransactions from "@/composables/Miles/Reports/milesCardsTransactions.js";
import useMilesCardsEmissions from "@/composables/Miles/Reports/milesCardsEmissions.js";
import useGeneratePDF from "@/composables/generatePDF.js";
import HayaTable from "@/components/general/HayaTable.vue";
import suppliersMixins from "@/mixins/suppliersMixins";
import milesCardsTransactionsMixins from "@/mixins/miles-cards-transactions/milesCardsTransactionsMixins";
import milesCardsMixin from "@/mixins/miles-cards/milesCardsMixin";

export default {
    components: { HayaTable },
    mixins: [milesCardsTransactionsMixins, suppliersMixins, milesCardsMixin],
    data() {
        return {
            excelFile: {
                header: "RELATÓRIO DE REEMLBOLSOS",
                fileName: "RELATÓRIO DE REEMLBOLSOS.xls",
                collumns: {
                    "ID da Operação": "milescard_transaction_id",
                    "ID Cartão de Milhas": "milescard_id",
                    Fornecedor: {
                        field: "supplier",
                        callback: (supplier) => {
                            return `${supplier.first_name} ${supplier.last_name}`;
                        },
                    },
                    "Pgm. Milhas": {
                        field: "miles_card",
                        callback: (milesCard) => {
                            return `${milesCard.miles_program}`;
                        },
                    },
                    "ID do Contrato": "contract_id",
                    "Data Reembolso": "created",
                    "Qtd. Reembolso": "total_miles",
                    "Valor da Compra (R$)": "suggested_value",
                    "Valor de Venda (R$)": "value",
                    Observações: "extra_info",
                    Usuário: {
                        field: "user",
                        callback: (user) => {
                            return `${user.first_name} ${user.last_name}`;
                        },
                    },
                },
                data: [],
                footer: [],
            },
        };
    },
    mounted() {
        this.milesCardTransactions.filters.operation.selected =
            "refund";
        this.getMilesCardTransactions();
        this.getAirlines();
    },
    setup() {
        document.title = "Relatório de Reembolsos - HAYA";

        const {
            pagination,
            list,
            loading,
            formatMilesOperation,
            filters,
            getUsers,
            listUsers,
        } = useMilesCardsTransactions();

        const { headers } = useMilesCardsEmissions();

        onBeforeMount(() => {
            getUsers();
        });

        const { generatePDF } = useGeneratePDF();

        return {
            pagination,
            headers,
            list,
            loading,
            formatMilesOperation,
            filters,
            listUsers,
            generatePDF,
        };
    },
    methods: {
        formatOperation(transaction) {
            let operation = "";
            let vcm = "";

            if (transaction.operation == "new") {
                if (transaction.is_vcm == "1") {
                    vcm = `<font class="corange" style="font-size: 24px; font-size: 16px;"> <a-tooltip> <template slot="title"> Milhas do VCM </template> • </a-tooltip> </font>`;
                }

                operation = `<font class="tag new">NOVO ${vcm}</font>`;
            }

            if (transaction.operation == "add") {
                operation = `<font class="tag add">ADIÇÃO</font>`;
            }

            if (transaction.operation == "subtract") {
                operation = `<font class="tag return">EMISSÃO</font>`;
            }

            if (transaction.operation == "transaction-canceled") {
                operation = `<font class="tag subtract">CANCELADO</font>`;
            }

            if (transaction.operation == "canceled") {
                operation = `<font class="tag subtract">CANCELADO</font>`;
            }

            if (transaction.operation == "refund") {
                operation = `<font class="tag cprimary">REEMBOLSO</font>`;
            }

            if (transaction.operation == "adjust") {
                operation = `<font class="tag adjust">AJUSTE</font>`;
            }

            if (transaction.operation == "purchase-canceled") {
                operation = `<font class="tag cancel-purchase">CANCELAMENTO DE COMPRA</font>`;
            }

            return operation;
        },
        formatMilesOperation(transaction) {
            let miles = "";
            if (transaction.operation == "new") {
                miles = `+${transaction.total_miles}`;
            }

            if (transaction.operation == "add") {
                miles = `+${transaction.total_miles}`;
            }

            if (transaction.operation == "refund") {
                miles = `+${transaction.total_miles}`;
            }

            if (transaction.operation == "return-miles") {
                miles = `+${transaction.total_miles}`;
            }

            if (transaction.operation == "transaction-canceled") {
                miles = `+${transaction.total_miles}`;
            }

            if (transaction.operation == "canceled") {
                miles = `+${transaction.total_miles}`;
            }

            if (transaction.operation == "subtract") {
                miles = `-${transaction.total_miles}`;
            }
            return miles;
        },
    },
};
</script>
