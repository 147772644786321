const useMilesCardsEmissions = () => {

  const headers = [{
      title: "ID Operação",
      dataIndex: "milescard_transaction_id",
      key: "milescard_transaction_id",
      class: "milescard_transaction_id",
    },
    {
      title: "Qtd. Milhas",
      scopedSlots: {
        customRender: "total_miles",
      },
    },

    {
      title: "Operação",
      scopedSlots: {
        customRender: "operation",
      },
    },
    {
      title: "ID Contrato",

      scopedSlots: {
        customRender: "contract_id",
      },
    },
    {
      title: "Emissor",
      dataIndex: "user",
      key: "user",
      scopedSlots: {
        customRender: "user",
      },
    },
    {
      title: "Data/Hora",
      scopedSlots: {
        customRender: "created",
      },
    },

  ];

  return {
    headers
  };
};


export default useMilesCardsEmissions;